import React from 'react';
import { message, Switch, Tooltip } from 'antd';
import { setBankAccountSbpEnabled } from 'modules/bankAccounts/store';
import { BankAccount } from 'modules/bankAccounts/types/bankAccount.interface';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useUserPermissions } from 'policies/hooks/useUserPermissions';
import { permissions } from 'policies/permissions';

interface Props {
  account: BankAccount;
}

export const BankAccountsListPageSbpEnabledColumn: React.FC<Props> = ({ account }) => {
  const dispatch = useAppDispatch();

  const { userCanAll } = useUserPermissions();

  async function handleSbpEnabledAccountChange(account: BankAccount, currentStatus: boolean) {
    try {
      await dispatch(
        setBankAccountSbpEnabled({
          id: account.id,
          sbpEnabled: currentStatus,
        })
      );

      message.success({
        content: 'Работа с СБП успешно изменена!',
        duration: 1.5,
      });
    } catch {
      message.error('Ошибка изменения работы с СБП!');
    }
  }

  return (
    <Tooltip
      placement="top"
      title={!account.phone && 'Нельзя изменить статус СБП, так как у аккаунта нет номера телефона'}
    >
      <Switch
        checked={account.sbpEnabled}
        onChange={(currentStatus) => handleSbpEnabledAccountChange(account, currentStatus)}
        disabled={
          !userCanAll(permissions.bankAccount.sbp.enable, permissions.bankAccount.sbp.disable) || !account.phone
        }
      />
    </Tooltip>
  );
};
