import { AccountType } from 'modules/owners/store/bankAccount/types';
import { AccountBank } from 'modules/owners/store/bankAccount/interfaces/accountBank.interface';

export enum BankAccountStatus {
  ACTIVE = 'active',
  BLOCKED = 'blocked',
}

export interface BankAccount {
  id: number;
  accountNumber: string;
  type: AccountType;
  amount: number;
  currencyCode: string;
  bank: AccountBank;
  ownerId: number;
  bic?: string;
  inEnabled: boolean;
  outEnabled: boolean;
  status: BankAccountStatus;
  phone?: string;
  sbpEnabled: boolean;
}
