import { App, Button, Table } from 'antd';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useBankAccountCardsColumns } from 'modules/bankAccounts/pages/list/components/CardsList/hooks/useBankAccountCardsColumns.hook';
import { updateCardCount } from 'modules/bankAccounts/store';
import { BankAccountCard } from 'modules/bankAccounts/types/bankAccountCard.interface';
import React from 'react';
import useSWR from 'swr';
import instance from 'utils/axios';
import { BankAccountListPageCardsListAddCard } from './components/AddCard';

interface Props {
  bankAccountId: number;
}

export const BankAccountsListPageCardsList: React.FC<Props> = ({ bankAccountId }) => {
  const { message, modal } = App.useApp();
  const dispatch = useAppDispatch();

  const { data, isLoading, mutate } = useSWR({ bankAccountId }, async () => {
    const {
      data: { data },
    } = await instance.get('/cards', {
      params: {
        bankAccountId,
      },
    });

    return data;
  });

  const columns = useBankAccountCardsColumns({
    onChangeStatusClick: handleEditCardStatus,
    onDeleteClick: (card) => {
      const { destroy } = modal.error({
        title: 'Удаление карты',
        content: (
          <>
            Вы уверены, что хотите
            <br />
            удалить карту?
          </>
        ),
        closable: true,
        width: '280px',
        footer: () => (
          <>
            <Button onClick={destroy}>Отмена</Button>
            <Button
              type="primary"
              danger
              onClick={() => {
                destroy();
                deleteCard(card);
              }}
            >
              Удалить
            </Button>
          </>
        ),
      });
    },
  });

  async function handleEditCardStatus(cardId: number, status: boolean) {
    try {
      if (status) {
        await instance.post('/cards/enable', { cardId });
      } else {
        await instance.post('/cards/disable', { cardId });
      }

      message.success('Статус карты успешно изменен');
    } catch {
      message.error('Не удалось изменить статус карты');
    } finally {
      await mutate();
    }
  }

  async function deleteCard(card: BankAccountCard) {
    try {
      await instance.post('/cards/delete', { id: card.id });

      dispatch(updateCardCount({ bankAccountId, type: 'decrement' }));

      message.success('Карта успешно удалена');
    } catch {
      message.error('Не удалось удалить карту');
    } finally {
      await mutate();
    }
  }

  return (
    <>
      <div>
        <BankAccountListPageCardsListAddCard bankAccountId={bankAccountId} onSubmit={() => mutate()} />
        <Table
          size="small"
          dataSource={data}
          loading={isLoading}
          columns={columns}
          pagination={false}
          bordered={false}
          rowKey={(card) => card.id}
          showHeader={false}
        />
      </div>
    </>
  );
};
