import { IBank } from 'modules/banks/types/IBank';
import { IMetaPagination } from 'types/IMetaPagination';
import { ReducerAction } from 'types/reducerAction.interface';

export interface IBanksState {
  banks: IBank[];
  isLoading: boolean;
  pagination: IMetaPagination;
  foundBinBank: IBank | null;
  duplicates: {
    mainBank: IBank | null;
    mergeBanks: IBank[];
  };
}

export interface IFetchBanksPayload {
  banks: IBank[];
  pagination: IMetaPagination;
}

export interface ISetPaginationPayload {
  page: number;
  perPage: number;
}

export interface IFetchBanksParams {
  id?: number;
  name?: string;
}

export interface IFetchFoundBinBankParams {
  bins: number[];
  source: string;
}

export interface IToggleBankPayload {
  id: number;
  enabled: boolean;
}

export interface IBankParams {
  id: number;
  name: string;
  nameEn: string;
  enabled: boolean;
  internal: boolean;
  countryCode: string;
  currencyCode: string;
  imageUrl: string;
  backgroundColors?: [string, string];
  createdAt?: string;
  updatedAt?: string;
  logoBigSizeUrl?: string;
  logoSmallSizeUrl?: string;
  logoInvertBigSizeUrl?: string;
  logoInvertSmallSizeUrl?: string;
}

export enum BanksActionsTypes {
  FETCH_BANKS = 'FETCH_BANKS',
  FETCH_BANK_BY_BIN = 'FETCH_BANK_BY_BIN',
  DELETE_BANK_BY_BIN = 'DELETE_BANK_BY_BIN',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_PAGINATION = 'SET_PAGINATION',
  UPDATE_BANK = 'UPDATE_BANK',
  TOGGLE_BANK = 'TOGGLE_BANK',
  TOGGLE_FOUND_BANK = 'TOGGLE_FOUND_BANK',
  ADD_MAIN_BANK_TO_REMOVE_DUPLICATE = 'ADD_MAIN_BANK_TO_REMOVE_DUPLICATE',
  ADD_MERGE_BANKS_TO_REMOVE_DUPLICATE = 'ADD_MERGE_BANKS_TO_REMOVE_DUPLICATE',
  DELETE_MERGE_BANKS_TO_REMOVE_DUPLICATE = 'DELETE_MERGE_BANKS_TO_REMOVE_DUPLICATE',
  CLEAR_CHECKED_MAIN_BANK = 'CLEAR_CHECKED_MAIN_BANK',
  CLEAR_CHECKED_MERGE_BANK = 'CLEAR_CHECKED_MERGE_BANK',
  CLEAR_MAIN_AND_MERGE_BANK = 'CLEAR_MAIN_AND_MERGE_BANK',
  DISABLED_CHECKED_MAIN_BANK = 'DISABLED_CHECKED_MAIN_BANK',
  DISABLED_CHECKED_MERGE_BANK = 'DISABLED_CHECKED_MERGE_BANK',
  CLEAR_DISABLED_CHECKED_BANKS = 'CLEAR_DISABLED_CHECKED_BANKS',
  SET_BANK_SBP = 'SET_BANK_SBP',
}

export interface IUpdateBankParams extends IBankParams {}

export interface ICreateBankParams extends IBankParams {}

export type IFetchBanksReducerAction = ReducerAction<IFetchBanksPayload, BanksActionsTypes.FETCH_BANKS>;

export type IFetchBankByBinReducerAction = ReducerAction<IBank, BanksActionsTypes.FETCH_BANK_BY_BIN>;

export type IDeleteBankByBinReducerAction = ReducerAction<IBank, BanksActionsTypes.DELETE_BANK_BY_BIN>;

export type ISetIsLoadingReducerAction = ReducerAction<boolean, BanksActionsTypes.SET_IS_LOADING>;

export type ISetPaginationReducerAction = ReducerAction<ISetPaginationPayload, BanksActionsTypes.SET_PAGINATION>;

export type IToggleBankReducerAction = ReducerAction<IToggleBankPayload, BanksActionsTypes.TOGGLE_BANK>;

export type IUpdateBankReducerAction = ReducerAction<IBank, BanksActionsTypes.UPDATE_BANK>;

export type IToggleFoundBankReducerAction = ReducerAction<IBank, BanksActionsTypes.TOGGLE_FOUND_BANK>;

export type IClearCheckedMainBankReducerAction = ReducerAction<IBank, BanksActionsTypes.CLEAR_CHECKED_MAIN_BANK>;

export type IClearCheckedMergeBankReducerAction = ReducerAction<IBank, BanksActionsTypes.CLEAR_CHECKED_MERGE_BANK>;

export type IDisabledCheckedMainBankReducerAction = ReducerAction<IBank, BanksActionsTypes.DISABLED_CHECKED_MAIN_BANK>;

export type IClearDisabledCheckedBanksReducerAction = ReducerAction<
  IBank,
  BanksActionsTypes.CLEAR_DISABLED_CHECKED_BANKS
>;

export type IDisabledCheckedMergeBankReducerAction = ReducerAction<
  IBank,
  BanksActionsTypes.DISABLED_CHECKED_MERGE_BANK
>;

export type IAddMainBankToRemoveDuplicateReducerAction = ReducerAction<
  IBank,
  BanksActionsTypes.ADD_MAIN_BANK_TO_REMOVE_DUPLICATE
>;

export type IAddMergeBanksToRemoveDuplicateReducerAction = ReducerAction<
  IBank,
  BanksActionsTypes.ADD_MERGE_BANKS_TO_REMOVE_DUPLICATE
>;

export type IDeleteMergeBanksToRemoveDuplicateReducerAction = ReducerAction<
  IBank,
  BanksActionsTypes.DELETE_MERGE_BANKS_TO_REMOVE_DUPLICATE
>;

export type IDeleteMainAndMergeBanksReducerAction = ReducerAction<IBank[], BanksActionsTypes.CLEAR_MAIN_AND_MERGE_BANK>;

export type SetBankSbpReducerAction = ReducerAction<{ id: number; value: boolean }, BanksActionsTypes.SET_BANK_SBP>;

export type BanksReducerActions =
  | IFetchBanksReducerAction
  | IFetchBankByBinReducerAction
  | IDeleteBankByBinReducerAction
  | ISetIsLoadingReducerAction
  | ISetPaginationReducerAction
  | IToggleBankReducerAction
  | IUpdateBankReducerAction
  | IToggleFoundBankReducerAction
  | IAddMainBankToRemoveDuplicateReducerAction
  | IAddMergeBanksToRemoveDuplicateReducerAction
  | IDeleteMergeBanksToRemoveDuplicateReducerAction
  | IDeleteMainAndMergeBanksReducerAction
  | IClearCheckedMainBankReducerAction
  | IClearCheckedMergeBankReducerAction
  | IDisabledCheckedMainBankReducerAction
  | IDisabledCheckedMergeBankReducerAction
  | IClearDisabledCheckedBanksReducerAction
  | SetBankSbpReducerAction;
