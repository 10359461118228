import React from 'react';
import { App, Switch } from 'antd';

import { IBank } from 'modules/banks/types/IBank';
import { setBankSbp } from 'modules/banks/store/banks/actions';

import { useAppDispatch } from 'hooks/useAppDispatch.hook';

import instance from 'utils/axios';

interface Props {
  bank: IBank;
}

export const SbpEnabledBankColumn: React.FC<Props> = ({ bank }) => {
  const { message } = App.useApp();
  const dispatch = useAppDispatch();

  async function handleSbpEnabledBankChange(bank: IBank, checked: boolean) {
    try {
      await instance.post(`/banks/${checked ? 'enable-sbp' : 'disable-sbp'}`, { id: bank.id });

      dispatch(setBankSbp(bank.id, checked));

      message.success(`СБП для банка успешно ${checked ? 'включено' : 'выключено'}`);
    } catch {
      message.error(`Не удалось ${checked ? 'включить' : 'выключить'} СБП для банка`);
    }
  }

  return <Switch checked={bank.sbpEnabled} onChange={(checked) => handleSbpEnabledBankChange(bank, checked)} />;
};
