import React from 'react';
import { Form, Button, Spin, App } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { useRolesFromServer } from 'hooks/useDataFromServer';
import { Input } from 'components/Input';
import { LazySelect } from 'components/LazySelect';
import { useCreateUserRequest } from 'modules/users/hooks/useCreateUserRequest.hook';
import { CreateUserRequestParams } from 'modules/users/api/createUser.request';

interface Props {
  isRolesEditable?: boolean;
  agentId?: number;
  onSubmit?: () => void;
}

export const CreateUserForm: React.FC<Props> = ({ isRolesEditable = true, agentId, onSubmit }) => {
  const [form] = useForm<CreateUserRequestParams>();

  const { createUser, isCreatingUser } = useCreateUserRequest();

  const { message } = App.useApp();

  async function handleSubmit(): Promise<void> {
    let values = form.getFieldsValue();

    if (agentId) {
      values = { ...values, agentId };
    }

    try {
      await createUser(values);

      message.success('Пользователь успешно создан!');

      onSubmit?.();
    } catch {
      message.error('Не удалось создать пользователя');
    }
  }

  return (
    <Spin spinning={isCreatingUser}>
      <Form form={form}>
        <Input className="mb-2" addonBefore="Имя" name="name" />
        <Input className="mb-2" addonBefore="Email" name="email" />
        <Input className="mb-2" addonBefore="Пароль" name="password" />
        <Input className="mb-2" addonBefore="Ник Telegram" name="telegramUsername" />
        {isRolesEditable && (
          <Form.Item name="roles" label="Роли">
            <LazySelect mode="multiple" cacheKey="roles-select" useDataHook={useRolesFromServer} />
          </Form.Item>
        )}
        <Button type="primary" onClick={handleSubmit}>
          Создать
        </Button>
      </Form>
    </Spin>
  );
};
