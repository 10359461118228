import React, { useState } from 'react';

import { CreditCardOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Form, Switch, Tooltip, message, Image, Select, Popconfirm } from 'antd';

import { Input } from 'components/Input';

import { IOwnerCard } from 'modules/owners/store/ownersCards/types';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import {
  createOwnerCard,
  disableOwnerCard,
  enableOwnerCard,
  deleteOwnerCard,
} from 'modules/owners/store/ownersCards/actions';
import {
  deleteBankAccount,
  setBankAccountInEnabled,
  setBankAccountOutEnabled,
  setBankAccountSbpEnabled,
  setBankAccountStatus,
} from 'modules/owners/store/bankAccount/actions';
import { BankAccount, BankAccountStatus } from 'modules/owners/store/bankAccount/interfaces/bankAccount.interface';
import { AccountTypeEnum } from 'modules/owners/types/accountType.enum';
import { useUserPermissions } from 'policies/hooks/useUserPermissions';
import { permissions } from 'policies/permissions';
import { useTheme } from 'contexts';
import { OwnerCardsModalContentAccountModal } from '../AccountModal';
import { BankAccountType } from 'modules/owners/types/bankAccountType.interface';

interface Props {
  cards: IOwnerCard[];
  account: BankAccount;
  accountTypes: BankAccountType[];
  ownerId: number;
}

export const OwnerCardsModalContentAccountCard: React.FC<Props> = ({ account, cards, accountTypes }) => {
  const [isAddingNewAccountCard, setIsAddingNewAccountCard] = useState(false);
  const [isAddAccountModalVisible, setIsAddAccountModalVisible] = useState<boolean>(false);
  const [newOwnerCard, setNewOwnerCard] = useState<string>('');

  const dispatch = useAppDispatch();

  const { userCan, userCanAll } = useUserPermissions();

  const {
    currentTheme: {
      token: { colorBgBalance, colorBgLabelInput, colorBorderInput },
    },
  } = useTheme();

  function hasBankAccountCards(bankAccountId: number) {
    return cards.some((card) => card.bankAccountId === bankAccountId);
  }

  async function handleCreate(bankAccountId: number) {
    try {
      await dispatch(createOwnerCard({ cardNumber: newOwnerCard, accountId: bankAccountId }));

      setNewOwnerCard('');
      setIsAddingNewAccountCard(false);

      message.success('Карта создана!');
    } catch {
      message.error('Ошибка создания карты!');
    }
  }

  async function handleStatusChange(cardId: number, currentStatus: boolean) {
    try {
      if (currentStatus) {
        await dispatch(enableOwnerCard(cardId));
      } else {
        await dispatch(disableOwnerCard(cardId));
      }

      message.success({
        content: 'Карта успешно отредактирована!',
        duration: 1.5,
      });
    } catch {
      message.error('Ошибка редактирования карты!');
    }
  }

  async function handleInEnabledBankAccountChange(account: BankAccount, currentStatus: boolean) {
    try {
      await dispatch(
        setBankAccountInEnabled({
          id: account.id,
          inEnabled: currentStatus,
        })
      );

      message.success({
        content: 'Входящие платежи успешно изменены!',
        duration: 1.5,
      });
    } catch {
      message.error('Ошибка изменения входящих платежей!');
    }
  }

  async function handleOutEnabledBankAccountChange(account: BankAccount, currentStatus: boolean) {
    try {
      await dispatch(
        setBankAccountOutEnabled({
          id: account.id,
          outEnabled: currentStatus,
        })
      );

      message.success({
        content: 'Исходящие платежи успешно изменены!',
        duration: 1.5,
      });
    } catch {
      message.error('Ошибка изменения Исходящих платежей!');
    }
  }

  async function handleBankAccountStatusChange(account: BankAccount, currentStatus: string) {
    try {
      await dispatch(
        setBankAccountStatus({
          id: account.id,
          status: currentStatus,
        })
      );

      message.success({
        content: 'Статус счёта успешно изменён!',
        duration: 1.5,
      });
    } catch {
      message.error('Ошибка изменения статуса счёта!');
    }
  }

  async function handleDeleteBankAccount() {
    try {
      await dispatch(
        deleteBankAccount({
          id: account.id,
        })
      );

      message.success({
        content: 'Счёт удален!',
        duration: 1.5,
      });
    } catch {
      message.error('Ошибка удаления счёта!');
    }
  }

  async function handleDeleteBankCard(id: number) {
    try {
      await dispatch(
        deleteOwnerCard({
          id: id,
        })
      );

      message.success({
        content: 'Карта удалена!',
        duration: 1.5,
      });
    } catch {
      message.error('Ошибка удаления карты!');
    }
  }

  async function handleSbpEnabledChange(checked: boolean) {
    try {
      await dispatch(setBankAccountSbpEnabled(account.id, checked));

      message.success(`СБП успешно ${checked ? 'включено' : 'выключено'}`);
    } catch {
      message.error('Произошла ошибка при переключении СБП');
    }
  }

  return (
    <>
      {isAddAccountModalVisible && (
        <OwnerCardsModalContentAccountModal
          isShown={isAddAccountModalVisible}
          onCancel={() => setIsAddAccountModalVisible(false)}
          nameAction="Редактирование счёта"
          nameButton="Сохранить"
          bankAccountData={account}
          hasCards={hasBankAccountCards(account.id)}
          accountTypes={accountTypes}
        />
      )}
      <div className="flex flex-col w-full border rounded-lg mb-2" style={{ borderColor: colorBorderInput }}>
        <div style={{ backgroundColor: colorBgLabelInput }} className="rounded-lg">
          <div className="flex flex-row items-center justify-between pt-4 pb-2 px-4">
            <div className="flex flex-col flex-1">
              <div className="flex flex-row items-center justify-between">
                <Tooltip title={account.bank.name}>
                  <Image className="py-4" src={account.bank.image} width={90} preview={false} alt={account.bank.name} />
                </Tooltip>
                <div className="flex flex-col flex-1 pl-3">
                  <div className="flex flex-row gap-x-2 ml-1 justify-between">
                    <div className="flex flex-row gap-x-2 ml-1 ">
                      <div
                        className={`text-sm whitespace-nowrap font-semibold ${
                          !account.accountNumber && 'text-red-500'
                        }`}
                      >
                        Счет {account.accountNumber ? account.accountNumber : 'отсутствует'}
                      </div>
                      <div>
                        <p
                          className="rounded-full px-2"
                          style={
                            account.type.id === AccountTypeEnum.OPERATING
                              ? { color: '#335366', backgroundColor: '#C2E9FF' }
                              : { color: '#33663C', backgroundColor: '#C0F2B1' }
                          }
                        >
                          {account.type.name}
                        </p>
                      </div>
                    </div>
                    <div className="flex end">
                      <Tooltip title="Редактировать счёт">
                        <Button
                          onClick={() => setIsAddAccountModalVisible(true)}
                          shape="circle"
                          type="primary"
                          icon={<EditOutlined />}
                          disabled={!userCan(permissions.bankAccount.update)}
                          className="mr-4"
                        />
                      </Tooltip>
                      <Tooltip title="Удалить счёт">
                        <Popconfirm
                          title="Вы уверены?"
                          okType="danger"
                          okText="Да"
                          cancelText="Нет"
                          onConfirm={handleDeleteBankAccount}
                        >
                          <Button
                            shape="circle"
                            type="primary"
                            danger
                            icon={<DeleteOutlined />}
                            disabled={!userCan(permissions.bankAccount.delete)}
                          />
                        </Popconfirm>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="flex flex-row w-full justify-between mt-2 mb-2">
                    <div className="ml-1">
                      <div className={`text-sm font-semibold ${!account.bic && 'text-gray-300'}`}>
                        БИК {account.bic ? account.bic : 'отсутствует'}
                      </div>
                      <div className={`text-sm font-semibold ${!account.bic && 'text-gray-300'}`}>
                        Номер телефона {account.phone ? account.phone : 'отсутствует'}
                      </div>
                      <div className="flex flex-row">
                        <p
                          style={{
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            backgroundColor: colorBgBalance,
                          }}
                          className="rounded-full mb-1 mt-1 border-black"
                        >
                          Баланс: {account.amount?.toLocaleString('ru-RU') ?? ''} ₽
                        </p>
                      </div>
                    </div>
                    <div className="flex items-end">
                      <Button
                        disabled={isAddingNewAccountCard}
                        type="default"
                        onClick={() => setIsAddingNewAccountCard(true)}
                      >
                        Добавить карту
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex flex-row gap-x-4 mb-2 items-center">
                  <Select
                    options={[
                      { value: BankAccountStatus.ACTIVE, label: 'Активный' },
                      { value: BankAccountStatus.BLOCKED, label: 'Заблокирован' },
                    ]}
                    disabled={!userCan(permissions.bankAccount.block)}
                    value={account.status}
                    onChange={(value: string) => handleBankAccountStatusChange(account, value as BankAccountStatus)}
                    style={{ minWidth: 150 }}
                  />
                  <div className="flex gap-x-2">
                    <Switch
                      checked={account.inEnabled}
                      onChange={(currentStatus) => handleInEnabledBankAccountChange(account, currentStatus)}
                      disabled={!userCanAll(permissions.bankAccount.in.enable, permissions.bankAccount.in.disable)}
                    />
                    Входящие
                  </div>
                  <div className="flex gap-x-2">
                    <Switch
                      checked={account.outEnabled}
                      onChange={(currentStatus) => handleOutEnabledBankAccountChange(account, currentStatus)}
                      disabled={!userCanAll(permissions.bankAccount.out.enable, permissions.bankAccount.out.disable)}
                    />
                    Исходящие
                  </div>
                  <div className="flex gap-x-2">
                    <Switch
                      checked={account.sbpEnabled}
                      onChange={handleSbpEnabledChange}
                      disabled={!userCanAll(permissions.bankAccount.sbp.enable, permissions.bankAccount.sbp.disable)}
                    />
                    СБП
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {isAddingNewAccountCard && (
              <div
                className="flex flex-row px-4 py-2 gap-x-2 items-center justify-between"
                style={{ borderColor: colorBorderInput, borderTopWidth: '1px' }}
              >
                <div className="w-3/5">
                  <Form.Item className="mb-0" name={['newCard']}>
                    <Input
                      onChange={(e) => setNewOwnerCard(e.target.value)}
                      placeholder="0000 0000 0000 0000"
                      addonBefore="Номер карты"
                      className="w-full leading-6"
                    />
                  </Form.Item>
                </div>
                <div className="flex flex-row gap-x-2">
                  <Button
                    disabled={newOwnerCard.length === 0}
                    type="primary"
                    onClick={() => {
                      handleCreate(account.id);
                    }}
                  >
                    Сохранить
                  </Button>
                  <Button
                    type="default"
                    onClick={() => {
                      setIsAddingNewAccountCard(false);
                    }}
                  >
                    Отмена
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          {cards.map((card, index) => {
            if (card.bankAccountId !== account.id) {
              return;
            }

            return (
              <div
                key={index}
                style={{
                  borderColor: colorBorderInput,
                  ...(cards.length - 1 !== index
                    ? { borderBottomWidth: '0px' }
                    : { borderBottomRightRadius: '8px', borderBottomLeftRadius: '8px' }),
                }}
                className="flex flex-row border-t p-2 gap-x-2 px-4 items-center justify-between"
              >
                <div className="flex flex-row gap-x-6">
                  <CreditCardOutlined />
                  <div>{card.cardNumber}</div>
                </div>
                <div>
                  <Switch
                    checked={card.status}
                    className="mr-4"
                    onChange={(checked: boolean) => handleStatusChange(card.id, checked)}
                  />
                  <Tooltip title="Удалить карту">
                    <Popconfirm
                      title="Вы уверены?"
                      okType="danger"
                      okText="Да"
                      cancelText="Нет"
                      onConfirm={() => handleDeleteBankCard(card.id)}
                    >
                      <Button
                        shape="circle"
                        type="primary"
                        danger
                        icon={<DeleteOutlined />}
                        disabled={!userCan(permissions.card.delete)}
                      />
                    </Popconfirm>
                  </Tooltip>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
