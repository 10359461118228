import React, { useMemo } from 'react';

import { Button, Switch, Tooltip } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { CreditCardOutlined, DeleteOutlined } from '@ant-design/icons';

import { ProtectedComponent } from 'policies/components/ProtectedComponent';
import { permissions } from 'policies/permissions';

import { BankAccountCard } from 'modules/bankAccounts/types/bankAccountCard.interface';
import { useUserPermissions } from 'policies/hooks/useUserPermissions';
import { BankAccountsListPageCardsListStatusColumn } from 'modules/bankAccounts/pages/list/components/CardsList/components/StatusColumn';

interface Props {
  onDeleteClick: (value: BankAccountCard) => void;
  onChangeStatusClick: (cardId: number, status: boolean) => void;
}

export function useBankAccountCardsColumns({
  onDeleteClick,
  onChangeStatusClick,
}: Props): ColumnType<BankAccountCard>[] {
  const { userCan } = useUserPermissions();

  return useMemo(
    () => [
      {
        title: 'Номер карты',
        key: 'cardNumber',
        dataIndex: 'cardNumber',
        align: 'left',
        width: 200,
        render: (cardNumber: string) => {
          return (
            <>
              <CreditCardOutlined />
              <span className="ml-2">{cardNumber}</span>
            </>
          );
        },
      },
      {
        title: 'Статус карты',
        key: 'status',
        align: 'left',
        render: (_, card) => {
          const fallbackComponent = <Switch checked={card.status} disabled={true} />;

          return (
            <>
              {!card.isDeleted ? (
                <ProtectedComponent
                  requiredPermissions={[permissions.card.update]}
                  fallbackComponent={fallbackComponent}
                >
                  <BankAccountsListPageCardsListStatusColumn
                    checked={card.status}
                    onChange={(status: boolean) => onChangeStatusClick(card.id, status)}
                  />
                </ProtectedComponent>
              ) : (
                <span className={card.isDeleted ? 'text-gray-400' : ''}>Удалена</span>
              )}
            </>
          );
        },
      },
      {
        title: 'Действия',
        align: 'right',
        render: (_, card) => {
          return (
            <>
              <ProtectedComponent requiredPermissions={[permissions.card.update]}>
                <Tooltip title="Удалить карту">
                  <Button
                    onClick={() => onDeleteClick(card)}
                    shape="circle"
                    type="primary"
                    danger
                    icon={<DeleteOutlined />}
                    disabled={!userCan(permissions.card.delete)}
                  />
                </Tooltip>
              </ProtectedComponent>
            </>
          );
        },
      },
    ],
    []
  );
}
