import React, { useEffect, useMemo } from 'react';

import { App, Button, Drawer, Form, Input, Radio } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { LazySelect } from 'components/LazySelect';

import { CreateBankAccountParams } from 'modules/owners/store/bankAccount/types';

import { useBanksFromServer } from 'hooks/useDataFromServer';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';

import { createBankAccount, updateBankAccount } from 'modules/owners/store/bankAccount/actions';
import { BankAccount } from 'modules/owners/store/bankAccount/interfaces/bankAccount.interface';
import { BankAccountType } from 'modules/owners/types/bankAccountType.interface';
import { useAppSelector } from 'hooks/useAppSelector.hook';

interface Props {
  isShown: boolean;
  ownerId?: number;
  onCancel: () => void;
  nameAction: string;
  nameButton: string;
  bankAccountData?: BankAccount;
  accountTypes: BankAccountType[];
  hasCards?: boolean;
}

export const OwnerCardsModalContentAccountModal: React.FC<Props> = ({
  isShown,
  ownerId,
  onCancel,
  nameAction,
  nameButton,
  bankAccountData,
  hasCards,
  accountTypes,
}) => {
  const [form] = useForm<CreateBankAccountParams>();

  const dispatch = useAppDispatch();

  const { accounts } = useAppSelector((state) => state.bankAccount);

  const { message } = App.useApp();

  const account = useMemo(() => {
    return accounts.find((account) => account.id === bankAccountData?.id);
  }, [bankAccountData, accounts]);

  useEffect(() => {
    if (bankAccountData) {
      form.setFieldsValue({
        bankId: bankAccountData.bank.id,
        accountNumber: bankAccountData.accountNumber,
        bic: bankAccountData.bic,
        type: bankAccountData.type.id,
        phone: bankAccountData.phone,
      });
    }
    return () => {
      form.resetFields();
    };
  }, [isShown]);

  async function handleSubmitPress() {
    if (ownerId) {
      try {
        await dispatch(createBankAccount({ ...form.getFieldsValue(), ownerId }));

        message.success('Счет владельца успешно создан');
        onCancel();
      } catch (error) {
        message.error('Не удалось создать счет владельца.');
      }
    }
    if (bankAccountData) {
      const id = bankAccountData.id;

      try {
        await dispatch(updateBankAccount({ ...form.getFieldsValue(), id }));

        message.success('Счет владельца успешно отредактирован');
        onCancel();
      } catch (error) {
        message.error('Не удалось отредактировать счет владельца.');
      }
    }
  }

  return (
    <Drawer title={nameAction} open={isShown} onClose={onCancel}>
      <Form form={form} onFinish={handleSubmitPress}>
        <div className="flex flex-col w-full">
          <Form.Item
            name="bankId"
            rules={[
              {
                validator: (_, value) => {
                  if (hasCards && value !== bankAccountData?.bank.id) {
                    return Promise.reject('Нельзя поменять банк, если у него есть карты');
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <LazySelect
              className="w-full"
              allowClear
              placeholder="Банк"
              useDataHook={useBanksFromServer}
              cacheKey="provider-select"
            />
          </Form.Item>
          <Form.Item
            name="accountNumber"
            rules={[
              {
                pattern: /^[a-zA-Z0-9]+$/,
                message: 'Номер счёта может содержать только буквы латинского алфавита и цифры',
              },
            ]}
          >
            <Input addonBefore="Номер счета" placeholder="00000000000000000000" type="text" />
          </Form.Item>
          <Form.Item
            name="bic"
            rules={[
              {
                pattern: /^[0-9]+$/,
                message: 'BIC должен состоять из цифр',
              },
            ]}
          >
            <Input addonBefore="БИК" placeholder="000000000" type="text" />
          </Form.Item>
          <Form.Item name="phone">
            <Input addonBefore="Номер телефона" placeholder="+79999999999" type="text" disabled={account?.sbpEnabled} />
          </Form.Item>
          <div className="flex flex-col items-baseline gap-2">
            <p className="text-sm">Тип счета</p>
            <Form.Item name="type">
              <Radio.Group
                options={accountTypes.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                optionType="button"
              />
            </Form.Item>
          </div>

          <Button htmlType="submit" className="w-full" type="primary">
            {nameButton}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};
