import React, { useState } from 'react';

import { Button, Card, Row, Typography, App, Spin, Tooltip } from 'antd';
import { ArrowDownOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import instance from 'utils/axios';

import { RejectedTransactionsStatistic } from 'modules/rejectedTransactions/types/rejectedTransactionsStatistic.interface';
import { FetchRejectedTransactionsParams } from 'modules/rejectedTransactions/store/types/fetchRejectedTransactionsParams.interface';

interface Props {
  isRejectedTransactionsLoading: boolean;
  getFilters: () => FetchRejectedTransactionsParams;
}

export const RejectedTransactionsPageStatistic: React.FC<Props> = ({ getFilters, isRejectedTransactionsLoading }) => {
  const { message } = App.useApp();

  const [statistic, setStatistic] = useState<RejectedTransactionsStatistic>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function fetchStatistic(filters: FetchRejectedTransactionsParams) {
    if (!('from' in filters) && !('to' in filters)) {
      message.error('Необходимо применить диапазон дат!');

      return;
    }

    try {
      setIsLoading(true);

      const {
        data: { data },
      } = await instance.get('/rejected-orders/statistic', {
        params: filters,
      });

      setStatistic(data);
    } catch {
      message.error('Не удалось получить статистику');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="flex items-center">
      <Button
        loading={isLoading}
        disabled={isRejectedTransactionsLoading}
        onClick={() => fetchStatistic(getFilters())}
        type="primary"
        className="mr-2"
      >
        Запросить статистику
      </Button>
      <Tooltip title="Запрос статистики осуществляется в соответствии с заданными фильтрами">
        <QuestionCircleOutlined className="ml-2 mr-4" />
      </Tooltip>
      <Spin spinning={isLoading}>
        <Row className="flex">
          <Card className="mr-1">
            <div className="flex">
              <div className="mr-5">
                <Typography.Text className="text-customGray">Входящие</Typography.Text>
                <div>
                  <ArrowDownOutlined className="text-xl text-customGreen mr-2" />
                  <Typography.Text className="text-lg">{statistic?.count.toLocaleString('ru-RU')}</Typography.Text>
                </div>
              </div>
              <div className="mr-5">
                <Typography.Text className="text-customGray">Сумма</Typography.Text>
                <div>
                  <Typography.Text className="text-lg">
                    {statistic ? statistic.totalSum.toLocaleString('ru-RU') + ' \u20BD' : '-'}
                  </Typography.Text>
                </div>
              </div>
              <div>
                <Typography.Text className="text-customGray">Средняя сумма</Typography.Text>
                <div>
                  <Typography.Text className="text-lg">
                    {statistic ? statistic?.averageSum.toLocaleString('ru-RU') + ' \u20BD' : '-'}
                  </Typography.Text>
                </div>
              </div>
            </div>
          </Card>
        </Row>
      </Spin>
    </div>
  );
};
