import React, { useEffect, useState } from 'react';

import { App, Button, Drawer, Form, Radio } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { PlusOutlined } from '@ant-design/icons';

import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useBankAccountsTypesFromServer, useBanksFromServer, useOwnersFromServer } from 'hooks/useDataFromServer';

import { Input } from 'components/Input';
import { LazySelect } from 'components/LazySelect';

import { createBankAccount } from 'modules/bankAccounts/store';
import { CreateBankAccountParams } from 'modules/bankAccounts/store/types/createBankAccountParams.interface';

import { ProtectedComponent } from 'policies/components/ProtectedComponent';
import { permissions } from 'policies/permissions';

interface Props {}

export const BankAccountsListCreateDrawer: React.FC<Props> = () => {
  const [form] = useForm<CreateBankAccountParams>();

  const [isShown, setIsShown] = useState<boolean>(false);

  const [accountTypes] = useBankAccountsTypesFromServer();

  const { message } = App.useApp();

  const dispatch = useAppDispatch();

  useEffect(() => {
    form.resetFields();
  }, [isShown]);

  async function handleSubmitPress() {
    try {
      await dispatch(createBankAccount(form.getFieldsValue())).unwrap();

      message.success('Счет владельца успешно создан');
      setIsShown(false);
    } catch (error) {
      message.error('Не удалось создать счет владельца.');
    }
  }

  function handleClose() {
    form.resetFields();
    setIsShown(false);
  }

  return (
    <>
      <ProtectedComponent requiredPermissions={[permissions.bankAccount.create]} fallbackComponent={<div />}>
        <Button icon={<PlusOutlined />} shape="round" type="primary" onClick={() => setIsShown(true)}>
          Создать
        </Button>
      </ProtectedComponent>

      <Drawer open={isShown} title="Создание счёта" onClose={handleClose}>
        <Form form={form} onFinish={handleSubmitPress} className="h-full">
          <div className="flex flex-col justify-end h-full">
            <div>
              <div className="border-b border-gray-100 pb-6 mb-10">
                <Form.Item name="ownerId">
                  <LazySelect
                    className="w-full"
                    allowClear
                    placeholder="Владелец"
                    useDataHook={useOwnersFromServer}
                    dataTransformer={(owner) => ({ label: `${owner.name} [${owner.id}]`, value: owner.id })}
                    cacheKey="owner-select"
                  />
                </Form.Item>
                <Form.Item name="bankId">
                  <LazySelect
                    className="w-full"
                    allowClear
                    placeholder="Банк"
                    useDataHook={useBanksFromServer}
                    cacheKey="provider-select"
                  />
                </Form.Item>
                <Form.Item
                  name="accountNumber"
                  rules={[
                    {
                      pattern: /^[a-zA-Z0-9]+$/,
                      message: 'Номер счёта может содержать только буквы латинского алфавита и цифры',
                    },
                  ]}
                >
                  <Input addonBefore="Номер счета" placeholder="00000000000000000000" type="text" />
                </Form.Item>
                <Form.Item
                  name="bic"
                  rules={[
                    {
                      pattern: /^[0-9]+$/,
                      message: 'BIC должен состоять из цифр',
                    },
                  ]}
                >
                  <Input addonBefore="БИК" placeholder="000000000" type="text" />
                </Form.Item>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      pattern: /^\+?[0-9]+$/,
                      message: 'Номер телефона может начинаться с "+" и затем состоять из цифр',
                    },
                  ]}
                >
                  <Input addonBefore="Номер телефона" placeholder="+7 000 000 0000" type="text" />
                </Form.Item>
                <div className="flex items-center justify-between gap-2">
                  <p className="text-sm">Тип счета</p>
                  <Form.Item name="type" className=" mb-0">
                    <Radio.Group
                      options={accountTypes.map((item) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                      className="flex whitespace-nowrap"
                      optionType="button"
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="mt-auto">
              <Button htmlType="submit" className="w-full" type="primary">
                Добавить счёт
              </Button>
            </div>
          </div>
        </Form>
      </Drawer>
    </>
  );
};
