import React, { useEffect, useState } from 'react';
import { App, Table, Typography } from 'antd';
import useSWR from 'swr';
import api from 'utils/axios';
import { Device } from 'modules/devices/types/device.interface';
import { useTableColumns } from 'modules/devices/pages/list/useTableColumns.hook';
import { DeviceListPageAssignOwnerModal } from 'modules/devices/pages/list/components/AssginOwnerModal';
import instance from 'utils/axios';
import { IMetaPagination } from 'types/IMetaPagination';
import { Pagination } from 'components/Pagination';
import { ProtectedComponent } from 'policies/components/ProtectedComponent';
import { permissions } from 'policies/permissions';

export const DevicesListPage: React.FC = () => {
  const [deviceToAttach, setDeviceToAttach] = useState<Device>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);

  const { data, isLoading, mutate } = useSWR([currentPage, perPage, 'devices-list'], async ([page, perPage]) => {
    const { data } = await api.get<{ data: Device[]; meta: IMetaPagination }>('/device', { params: { page, perPage } });

    return data;
  });

  const columns = useTableColumns({
    onCloseSessionPress: handleCloseConnectionPress,
    onAssignOwnerPress: handleAssignOwnerPress,
  });

  const { message } = App.useApp();

  useEffect(() => {
    if (!data) return;

    setCurrentPage(data?.meta.page ?? 1);
    setPerPage(data?.meta.perPage ?? 20);
  }, [data?.meta]);

  async function handleAssignOwnerPress(device: Device) {
    setDeviceToAttach(device);
  }

  async function handleCloseConnectionPress(device: Device) {
    try {
      await instance.post('/device/logout', { id: device.id });

      message.success('Сессия успешно завершена');
    } catch {
      message.error('Не удалось закончить сессию на устройстве владельца');
    }
  }

  return (
    <>
      <DeviceListPageAssignOwnerModal
        device={deviceToAttach}
        onCancel={() => setDeviceToAttach(undefined)}
        onSubmit={() => {
          mutate();
          setDeviceToAttach(undefined);
        }}
      />
      <Typography.Title>Устройства</Typography.Title>
      <ProtectedComponent requiredPermissions={[permissions.device.view]}>
        <>
          <Table
            loading={isLoading}
            dataSource={data?.data}
            columns={columns}
            pagination={false}
            rowKey={(record) => record.id}
          />
          <Pagination
            pagination={{ total: data?.meta.total ?? 0, pages: data?.meta.pages ?? 0, page: currentPage, perPage }}
            isLoading={isLoading}
            onPaginationChange={(page, perPage) => {
              setCurrentPage(page);
              setPerPage(perPage);
            }}
          />
        </>
      </ProtectedComponent>
    </>
  );
};
