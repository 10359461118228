import React, { FC, useEffect } from 'react';

import { App, Table } from 'antd';

import { Filters } from 'components/Filters';
import { Pagination } from 'components/Pagination';

import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useAppSelector } from 'hooks/useAppSelector.hook';
import { useMemoFilters } from 'hooks/useMemoFilters/useMemoFilters';

import { filtersSchema } from './filters';

import { fetchRejectedTransactions } from 'modules/rejectedTransactions/store';

import { FetchRejectedTransactionsParams } from 'modules/rejectedTransactions/store/types/fetchRejectedTransactionsParams.interface';

import { useRejectedTransactionsColumns } from './hooks/useRejectedTransactionsColumns.hook';
import { RejectedTransactionsPageReportButton } from './components/ReportButton';
import { RejectedTransactionsPageStatistic } from './components/Statistic';

import { permissions } from 'policies/permissions';
import { ProtectedComponent } from 'policies/components/ProtectedComponent';

export const RejectedTransactionsPage: FC = () => {
  const { memoFilters, setMemoFilters } = useMemoFilters<FetchRejectedTransactionsParams>();

  const { message } = App.useApp();

  const dispatch = useAppDispatch();

  const columns = useRejectedTransactionsColumns();

  const {
    rejectedTransactions,
    pagination,
    pagination: { perPage, page },
    isLoading,
  } = useAppSelector((state) => state.rejectedTransactions);

  useEffect(() => {
    fetch(memoFilters, page, perPage);
  }, [memoFilters, page, perPage]);

  async function fetch(memoFilters: FetchRejectedTransactionsParams, page: number, perPage: number) {
    try {
      await dispatch(fetchRejectedTransactions({ ...memoFilters, page, perPage })).unwrap();
    } catch {
      message.error('Не удалось получить список банков');
    }
  }

  function handlePaginationChange(page: number, perPage: number): void {
    fetch(memoFilters, page, perPage);
  }

  return (
    <>
      <Filters
        headerTitle="Отклонённые заявки"
        filters={filtersSchema}
        value={memoFilters}
        onSubmit={setMemoFilters}
        customButtons={(getFilters) => (
          <>
            <ProtectedComponent requiredPermissions={[permissions.rejectedOrder.report]} fallbackComponent={<div />}>
              <RejectedTransactionsPageReportButton getFilters={getFilters} />
            </ProtectedComponent>
            <RejectedTransactionsPageStatistic getFilters={getFilters} isRejectedTransactionsLoading={isLoading} />
          </>
        )}
      />
      <Table
        columns={columns}
        dataSource={rejectedTransactions}
        loading={isLoading}
        pagination={false}
        rowKey={(record) => record.remoteOrderId}
      />
      <Pagination pagination={pagination} isLoading={isLoading} onPaginationChange={handlePaginationChange} />
    </>
  );
};
