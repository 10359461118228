import { App, Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import instance from 'utils/axios';

import { IOwnerCard } from 'modules/owners/store/ownersCards/types';
import { PaymentRequisitesType } from 'enums/paymentRequisitesType.enum';
import { BankAccount, BankAccountType } from 'modules/owners/types/bankAccount.interface';
import { Order } from 'modules/orders/types/order.interface';

interface Props {
  order: Order;
  ownerId: number;
  initialRequisitesType?: PaymentRequisitesType;
  onRequisitesChange: (bankAccountId: number, cardId?: number) => void;
}

interface AvailableForInOrderOwner {
  id: number;
  name: string;
}

export const OrdersPageEditModalInOrderOwnerSelect: React.FC<Props> = ({
  order,
  ownerId,
  onRequisitesChange,
  initialRequisitesType,
}) => {
  const [availableOwners, setAvailableOwners] = useState<AvailableForInOrderOwner[]>([]);
  const [availableCards, setAvailableCards] = useState<IOwnerCard[]>([]);
  const [availableAccounts, setAvailableAccounts] = useState<BankAccount[]>([]);
  const [selectedOwnerId, setSelectedOwnerId] = useState<number>(ownerId);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requisites, setRequisites] = useState({
    label:
      order.owners[0].requisitesType === PaymentRequisitesType.CARD
        ? order.owners[0].cardNumber
        : order.owners[0].bankAccountNumber,
    value:
      order.owners[0].requisitesType === PaymentRequisitesType.CARD
        ? order.owners[0].cardId
        : order.owners[0].bankAccountId,
  });

  const { message } = App.useApp();

  useEffect(() => {
    fetchAvailableOwners();
  }, [order]);

  useEffect(() => {
    if (!selectedOwnerId) {
      clear();
    }

    if (
      PaymentRequisitesType.ACCOUNT === initialRequisitesType ||
      PaymentRequisitesType.PHONE === initialRequisitesType
    ) {
      fetchAvailableAccounts();
    } else {
      fetchAvailableCards();
    }

    if (order.owners[0].ownerId !== selectedOwnerId) {
      setRequisites({ label: undefined, value: undefined });
    }
  }, [selectedOwnerId, initialRequisitesType]);

  async function fetchAvailableCards() {
    try {
      setIsLoading(true);

      const {
        data: { data },
      } = await instance.get('/cards', { params: { ownerId: selectedOwnerId, existBankAccountId: 1 } });

      setAvailableCards(data);
    } catch {
      message.error('Не удалось получить список карт владельца');
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchAvailableAccounts() {
    try {
      setIsLoading(true);

      const {
        data: { data },
      } = await instance.get('/bank-accounts', {
        params: { ownerId: selectedOwnerId, type: BankAccountType.OPERATING },
      });

      setAvailableAccounts(data);

      const owner = order.owners[0];

      if (
        selectedOwnerId === owner.ownerId &&
        owner.requisitesType === PaymentRequisitesType.PHONE &&
        !requisites.label
      ) {
        const account = data.find((account: BankAccount) => account.id === requisites.value);

        if (!account) return;

        setRequisites({
          label: account.accountNumber,
          value: account.id,
        });
      }
    } catch {
      message.error('Не удалось получить список счетов владельца');
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchAvailableOwners() {
    try {
      clear();
      setIsLoading(true);

      const {
        data: { data },
      } = await instance.get('/owners/owners-for-in-order', { params: { orderId: order.id } });

      setAvailableOwners(data);
    } catch {
      message.error('Не удалось получить список владельцев');
    } finally {
      setIsLoading(false);
    }
  }

  function clear(): void {
    setAvailableCards([]);
    setAvailableAccounts([]);
  }

  return (
    <>
      <Form.Item name="ownerId" label="Идентификатор владельца">
        <Select
          options={availableOwners.map(({ id, name }) => ({ label: id + ' ' + name, value: id }))}
          onChange={(value) => setSelectedOwnerId(value)}
          loading={isLoading}
          showSearch
        />
      </Form.Item>
      {PaymentRequisitesType.CARD === initialRequisitesType && (
        <Form.Item label="№ карты владельца">
          <Select
            value={requisites}
            defaultValue={requisites}
            options={availableCards.map(({ cardNumber, id }) => ({
              label: cardNumber,
              value: id,
            }))}
            onChange={(value) => {
              const cardId = value as unknown as number;

              const card = availableCards.find((card) => {
                return card.id === cardId;
              });

              setRequisites({ value: card!.id, label: card!.cardNumber });
              onRequisitesChange(card!.bankAccountId, card!.id);
            }}
            loading={isLoading}
          />
        </Form.Item>
      )}
      {(PaymentRequisitesType.ACCOUNT === initialRequisitesType ||
        PaymentRequisitesType.PHONE === initialRequisitesType) && (
        <Form.Item label="№ банковского счета">
          <Select
            value={requisites}
            defaultValue={requisites}
            options={availableAccounts.map(({ accountNumber, id }) => ({
              label: accountNumber,
              value: id,
            }))}
            onChange={(value) => {
              console.log(value);
              const bankAccountId = value as unknown as number;

              const account = availableAccounts.find((account) => account.id === bankAccountId);

              setRequisites({ value: account!.id, label: account!.accountNumber });
              onRequisitesChange(account!.id);
            }}
            loading={isLoading}
          />
        </Form.Item>
      )}
      <Form.Item name="bankAccountId" hidden />
      <Form.Item name="cardId" hidden />
      <Form.Item name="requisitesType" hidden />
    </>
  );
};
