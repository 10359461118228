import { Dispatch } from 'redux';

import api from 'utils/axios';
import {
  BankAccountStatusParams,
  BankAccountInEnabledParams,
  BankAccountOutEnabledParams,
  BankAccountActionsTypes,
  SetBankAccountStatusReducerAction,
  SetBankAccountInEnabledReducerAction,
  SetBankAccountOutEnabledReducerAction,
  SetLoadingReducerAction,
  FetchBankAccountsReducerAction,
  CreateBankAccountReducerAction,
  DeleteBankAccountReducerAction,
  UpdateBankAccountReducerAction,
  CreateBankAccountParams,
  DeleteBankAccountParams,
} from 'modules/owners/store/bankAccount/types';
import { BankAccount } from 'modules/owners/store/bankAccount/interfaces/bankAccount.interface';
import { sanitizeRequestParams } from 'utils/sanitizeRequestParams';
import { IFetchOwnersCardsParams } from 'modules/owners/store/ownersCards/types';
import instance from 'utils/axios';

export function fetchBankAccounts(params: IFetchOwnersCardsParams) {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading());

      const {
        data: { data },
      } = await api.get('/bank-accounts', { params: sanitizeRequestParams(params) });

      dispatch<FetchBankAccountsReducerAction>({
        type: BankAccountActionsTypes.FETCH_BANK_ACCOUNTS,
        payload: { accounts: data, isLoading: false },
      });
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function createBankAccount(params: CreateBankAccountParams) {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading());

      const {
        data: { data },
      } = await api.post('/bank-accounts/create', { ...sanitizeRequestParams(params) });

      dispatch<CreateBankAccountReducerAction>({
        type: BankAccountActionsTypes.CREATE_BANK_ACCOUNT,
        payload: data as BankAccount,
      });
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function deleteBankAccount(params: DeleteBankAccountParams) {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading());

      await api.post('/bank-accounts/delete', { ...sanitizeRequestParams(params) });

      dispatch<DeleteBankAccountReducerAction>({
        type: BankAccountActionsTypes.DELETE_BANK_ACCOUNT,
        payload: params.id,
      });
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function updateBankAccount(params: {
  bankId: number;
  id: number;
  ownerId: number;
  type: string;
  accountNumber: string;
  bic: string;
}) {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading());

      const {
        data: { data },
      } = await api.post('/bank-accounts/update', { ...sanitizeRequestParams(params) });

      dispatch<UpdateBankAccountReducerAction>({
        type: BankAccountActionsTypes.UPDATE_BANK_ACCOUNT,
        payload: data as BankAccount,
      });
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function setBankAccountStatus(params: BankAccountStatusParams) {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading());

      const { id, status } = params;

      const url = status === 'active' ? 'activate' : 'block';

      const {
        data: { data },
      } = await api.post(`/bank-accounts/${url}`, { id });

      dispatch<SetBankAccountStatusReducerAction>({
        type: BankAccountActionsTypes.SET_BANK_ACCOUNT_STATUS,
        payload: data as BankAccount,
      });
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function setBankAccountInEnabled(params: BankAccountInEnabledParams) {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading());

      const { id, inEnabled } = params;

      const url = inEnabled ? 'enable-incoming' : 'disable-incoming';

      const {
        data: { data },
      } = await api.post(`/bank-accounts/${url}`, { id });

      dispatch<SetBankAccountInEnabledReducerAction>({
        type: BankAccountActionsTypes.SET_BANK_ACCOUNT_IN_ENABLED,
        payload: data as BankAccount,
      });
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function setBankAccountOutEnabled(params: BankAccountOutEnabledParams) {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setLoading());

      const { id, outEnabled } = params;

      const url = outEnabled ? 'enable-outcoming' : 'disable-outcoming';

      const {
        data: { data },
      } = await api.post(`/bank-accounts/${url}`, { id });

      dispatch<SetBankAccountOutEnabledReducerAction>({
        type: BankAccountActionsTypes.SET_BANK_ACCOUNT_OUT_ENABLED,
        payload: data as BankAccount,
      });
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function setLoading(value: boolean = true) {
  return (dispatch: Dispatch) => {
    dispatch<SetLoadingReducerAction>({
      type: BankAccountActionsTypes.SET_LOADING,
      payload: value,
    });
  };
}

export function setBankAccountSbpEnabled(id: number, value: boolean) {
  return async (dispatch: Dispatch) => {
    const url = value ? 'enable-sbp' : 'disable-sbp';

    await instance.post(`/bank-accounts/${url}`, { id });

    dispatch({
      type: BankAccountActionsTypes.SET_BANK_ACCOUNT_SBP_ENABLED,
      payload: { id, value },
    });
  };
}
