import { App, Pagination, Switch, Table } from 'antd';
import React, { useEffect, useMemo } from 'react';

import { Filters } from 'components/Filters';
import { filtersSchema } from 'modules/owners/pages/cards/filters';

import { paginationLocale } from 'constants/locale';

import {
  changePage,
  disableOwnerCard,
  enableOwnerCard,
  fetchCardsOwners,
} from 'modules/owners/store/ownersCards/actions';
import { IFetchOwnersCardsParams, IOwnerCard } from 'modules/owners/store/ownersCards/types';

import { useMemoFilters } from 'hooks/useMemoFilters/useMemoFilters';

import { ProtectedComponent } from 'policies/components/ProtectedComponent';

import { makeColumns } from './columns';

import { permissions } from 'policies/permissions';
import { useAppSelector } from 'hooks/useAppSelector.hook';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { ColumnType } from 'antd/lib/table';

interface Props {}

export const OwnersCardsPage: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  const {
    isLoading,
    cards,
    pagination: { page, total, perPage = 20 },
  } = useAppSelector(({ ownersCards }) => ownersCards);

  const { memoFilters, setMemoFilters } = useMemoFilters<IFetchOwnersCardsParams>();

  const { message } = App.useApp();

  useEffect(() => {
    dispatch(fetchCardsOwners({ ...memoFilters, page, perPage }));
  }, [memoFilters, page, perPage]);

  const columns = useMemo<ColumnType<IOwnerCard>[]>(
    () => [
      {
        title: 'Статус карты',
        key: 'status',
        align: 'center',
        render: (_, card) => {
          const fallbackComponent = <Switch checked={card.status} disabled={true} />;

          return (
            <>
              {!card.isDeleted ? (
                <ProtectedComponent
                  requiredPermissions={[permissions.card.update]}
                  fallbackComponent={fallbackComponent}
                >
                  <Switch checked={card.status} onClick={(checked: boolean) => handleStatusChange(card.id, checked)} />
                </ProtectedComponent>
              ) : (
                <span className={card.isDeleted ? 'text-gray-400' : ''}>Удалена</span>
              )}
            </>
          );
        },
      },
      ...makeColumns(),
    ],
    [cards]
  );

  function handleChangePage(page: number, perPage: number) {
    dispatch(changePage(page, perPage));
  }

  async function handleStatusChange(cardId: number, currentStatus: boolean) {
    try {
      if (currentStatus) {
        await dispatch(enableOwnerCard(cardId));
      } else {
        await dispatch(disableOwnerCard(cardId));
      }

      message.success({
        content: 'Карта успешно отредактирована!',
        duration: 1.5,
      });
    } catch {
      message.error('Ошибка редактирования карты!');
    }
  }

  return (
    <>
      <Filters headerTitle="Карты владельцев" filters={filtersSchema} value={memoFilters} onSubmit={setMemoFilters} />
      <Table
        size="small"
        dataSource={cards}
        loading={isLoading}
        columns={columns}
        pagination={false}
        bordered
        rowKey={(card) => card.id}
      />
      <Pagination
        current={page}
        pageSize={perPage}
        total={total}
        disabled={isLoading}
        onChange={handleChangePage}
        locale={paginationLocale}
        showSizeChanger
      />
    </>
  );
};
