import { BankAccount } from 'modules/owners/store/bankAccount/interfaces/bankAccount.interface';
import { FetchBankAccountsPayload } from 'modules/owners/store/bankAccount/interfaces/fetchBankAccountsPayload.interface';

import {
  BankAccountActionsTypes,
  BankAccountReducersActions,
  IBankAccountState,
} from 'modules/owners/store/bankAccount/types';

const initialState: IBankAccountState = {
  accounts: [],
  isLoading: false,
};

export function bankAccountsReducer(
  state = initialState,
  { type, payload }: BankAccountReducersActions
): IBankAccountState {
  switch (type) {
    case BankAccountActionsTypes.CREATE_BANK_ACCOUNT: {
      const account = payload as BankAccount;

      return { ...state, accounts: [account, ...state.accounts] };
    }

    case BankAccountActionsTypes.DELETE_BANK_ACCOUNT: {
      const accountIdToDelete = payload as number;
      return {
        ...state,
        accounts: state.accounts.filter((account) => account.id !== accountIdToDelete),
      };
    }

    case BankAccountActionsTypes.UPDATE_BANK_ACCOUNT: {
      const updateBankAccount = payload as BankAccount;

      return {
        ...state,
        accounts: state.accounts.map((account) => {
          return account.id === updateBankAccount.id ? updateBankAccount : account;
        }),
      };
    }

    case BankAccountActionsTypes.FETCH_BANK_ACCOUNTS: {
      const { accounts } = payload as unknown as FetchBankAccountsPayload;

      return { ...state, accounts, isLoading: false };
    }

    case BankAccountActionsTypes.SET_BANK_ACCOUNT_STATUS: {
      const changeBankAccountStatus = payload as BankAccount;

      return {
        ...state,
        accounts: state.accounts.map((account) => {
          return account.id === changeBankAccountStatus.id ? changeBankAccountStatus : account;
        }),
      };
    }

    case BankAccountActionsTypes.SET_BANK_ACCOUNT_IN_ENABLED: {
      const changeBankInEnabled = payload as BankAccount;

      return {
        ...state,
        accounts: state.accounts.map((account) => {
          return account.id === changeBankInEnabled.id ? changeBankInEnabled : account;
        }),
      };
    }

    case BankAccountActionsTypes.SET_BANK_ACCOUNT_OUT_ENABLED: {
      const changeBankOutEnabled = payload as BankAccount;

      return {
        ...state,
        accounts: state.accounts.map((account) =>
          account.id === changeBankOutEnabled.id ? changeBankOutEnabled : account
        ),
      };
    }

    case BankAccountActionsTypes.SET_BANK_ACCOUNT_SBP_ENABLED: {
      return {
        ...state,
        accounts: state.accounts.map((account) =>
          account.id === payload.id
            ? {
                ...account,
                sbpEnabled: payload.value,
              }
            : account
        ),
      };
    }

    case BankAccountActionsTypes.SET_LOADING: {
      const isLoading = payload as boolean;

      return { ...state, isLoading };
    }

    default:
      return state;
  }
}
