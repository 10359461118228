import React, { useEffect, useState } from 'react';
import { Button, Empty, Space, Spin } from 'antd';

import { fetchCardsOwners } from 'modules/owners/store/ownersCards/actions';
import { fetchBankAccounts } from 'modules/owners/store/bankAccount/actions';

import { CreditCardOutlined } from '@ant-design/icons';

import { useAppSelector } from 'hooks/useAppSelector.hook';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';

import { OwnerCardsModalContentAccountModal } from '../AccountModal';
import { OwnerCardsModalContentAccountCard } from '../AccountCard';
import { useBankAccountsTypesFromServer } from 'hooks/useDataFromServer';

interface Props {
  ownerId: number;
}

export const OwnerCardsModalContent: React.FC<Props> = ({ ownerId }) => {
  const [isAddAccountModalVisible, setIsAddAccountModalVisible] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { isLoading, cards } = useAppSelector(({ ownersCards }) => ownersCards);

  const { accounts } = useAppSelector(({ bankAccount }) => bankAccount);

  const [accountTypes] = useBankAccountsTypesFromServer();

  useEffect(() => {
    dispatch(fetchCardsOwners({ ownerId }));
    dispatch(fetchBankAccounts({ ownerId }));
  }, [ownerId]);

  return (
    <Space direction="vertical" className="w-full p-2">
      <Space className="justify-between w-full">
        <OwnerCardsModalContentAccountModal
          isShown={isAddAccountModalVisible}
          ownerId={ownerId}
          onCancel={() => setIsAddAccountModalVisible(false)}
          nameAction="Добавление счета"
          nameButton="Добавить счет"
          accountTypes={accountTypes}
        />
        <Button
          onClick={() => setIsAddAccountModalVisible(true)}
          type="primary"
          shape="round"
          className="mb-4"
          icon={<CreditCardOutlined />}
        >
          Добавить счет
        </Button>
      </Space>
      <Spin spinning={isLoading}>
        {accounts.length > 0 ? (
          <div className="flex flex-col gap-y-4">
            {accounts.map((account) => (
              <OwnerCardsModalContentAccountCard
                key={account.id}
                ownerId={ownerId}
                cards={cards}
                account={account}
                accountTypes={accountTypes}
              />
            ))}
          </div>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Нет данных" />
        )}
      </Spin>
    </Space>
  );
};
